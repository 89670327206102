<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.sipAccount"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="sip_number"
                      :label="$t('message.sip_number')"
                      >
                        <el-input
                            :placeholder="$t('message.sip_number')"
                            v-model="form.sip_number"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="sip_password"
                      :label="$t('message.sip_password')"
                      >
                        <el-input
                            :placeholder="$t('message.sip_password')"
                            v-model="form.sip_password"
                            size="medium"
                            type="password"
                            show-password
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="server_url"
                      :label="$t('message.server_url')"
                      >
                        <el-input
                            :placeholder="$t('message.server_url')"
                            v-model="form.server_url"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="port"
                      :label="$t('message.port')"
                      >
                        <el-input
                            :placeholder="$t('message.port')"
                            v-model="form.port"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="extension_type"
                      :label="$t('message.extension_type')"
                      >
                        <el-select size="medium" v-model="form.extension_type" clearable :placeholder="$t('message.extension_type')">
                          <el-option
                            v-for="(item, index) in number_types"
                            :key="item + index"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>     
                    </el-col>
                    
                    <el-col :span="12">
                      <el-form-item
                      prop="phones"
                      :label="$t('message.phones')"
                      >
                        <select-phones
                          v-model="form.phones"
                          :item_ids="form.phones"
                          :size="'medium'"
                          :placeholder="$t('message.phones')"
                        >
                        </select-phones>
                      </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                      <el-form-item
                      >
                        <el-button :disabled="form.number_exist" @click="registerUserAgentJSSIP()" :type="form.number_exist ? 'success' : 'primary' "> 
                          {{ form.number_exist ? $t('message.number_found_on_server') : $t('message.check_if_number_exists_in_server') }}   
                        </el-button>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import JsSIP from 'jssip';
import selectPhones from "@/components/multiSelects/multi-select-phone-number-for-outbound-call";

export default {
  mixins: [form, drawer],
  components: {
    selectPhones,
  },
  data() {
    return {
        number_types: [
          {
            value: 'sip',
            label: 'sip'
          }
        ],
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "sipAccounts/rules",
          model: "sipAccounts/model",
          columns: "sipAccounts/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "sipAccounts/store",
      }),
      registerUserAgentJSSIP() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let vm = this;
            let wss_url = 'wss://' + this.form.server_url + ':' + this.form.port + '/ws';
            let socket = new JsSIP.WebSocketInterface(wss_url);
            let sip_uri = this.form.extension_type + ':' + this.form.sip_number + '@' + this.form.server_url; 
            
            let configuration = {
                sockets  : [ socket ],
                uri      : sip_uri,
                password : this.form.sip_password
            };

            let coolPhone = new JsSIP.UA(configuration);
            coolPhone.start();
            coolPhone.register();
            coolPhone.on('registered', function(e){ 
                vm.form.number_exist = true;
                vm.$notify({
                    title: vm.$t('message.register'),
                    type: "success",
                    offset: 130,
                    message: vm.$t('message.phone_registered_message', {phone: vm.form.sip_number})
                });
            }); 

            coolPhone.on('registrationFailed', function(e){ 
                vm.form.number_exist = false;
                vm.$notify({
                    title: vm.$t('message.register'),
                    type: "error",
                    offset: 130,
                    message: vm.$t('message.invalid_phone_alert')
                });
            }); 
          }
        })
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                if(this.form.number_exist){
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
                }else{
                  this.$notify({
                      title: 'Ошибка ',
                      type: "error",
                      offset: 130,
                      message: "Пожалуйста, проверьте наличие внутреннего номера, нажав на кнопку Проверить..."
                  });
                }
              }
          });
      },
  },
};
</script>
